import { render, staticRenderFns } from "./ConfigActivities.vue?vue&type=template&id=ff7a2ba2&scoped=true"
import script from "./ConfigActivities.vue?vue&type=script&lang=js"
export * from "./ConfigActivities.vue?vue&type=script&lang=js"
import style0 from "./ConfigActivities.vue?vue&type=style&index=0&id=ff7a2ba2&prod&scoped=true&lang=scss"
import style1 from "./ConfigActivities.vue?vue&type=style&index=1&id=ff7a2ba2&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ff7a2ba2",
  null
  
)

export default component.exports